import gql from 'graphql-tag';
import moment from 'moment-mini';
import _get from 'lodash.get';
import emailjs, { init } from 'emailjs-com';
import { GraphQLClient } from 'graphql-request';

init(process.env.GATSBY_EMAIL_JS_ID); // eslint-disable-line

const url = process.env.GATSBY_APPSYNC_GRAPHQL_URL; // eslint-disable-line
const key = process.env.GATSBY_APPSYNC_GRAPHQL_API_KEY; // eslint-disable-line

const graphQLClient = new GraphQLClient(url, {
  headers: {
    'x-api-key': key,
  },
});

export const postReview = async input => {
  const formattedBotField = JSON.stringify(input.botField);
  const formattedBody = JSON.stringify(input.body);
  const formattedEmail = JSON.stringify(input.email);
  const formattedLocation = JSON.stringify(input.location);
  const formattedNickName = JSON.stringify(input.nickName);
  const date = JSON.stringify(moment().format('MM/DD/YYYY'));

  const mutation = gql(`
    mutation MyMutation {
      createReview(input: {body: ${formattedBody},
        botField: ${formattedBotField},
        email: ${formattedEmail},
        location: ${formattedLocation},
        nickName: ${formattedNickName},
        visible: false,
        unixTime: ${moment().unix()},
        wasCommission: ${input.wasCommission}
        date: ${date}}) {
        botField
        body
        email
        location
        visible
        nickName
        wasCommission
      }
    }
    `);

  try {
    const data = await graphQLClient.request(mutation);
    if (_get(data, 'createReview.body', '').length > 0) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('error in posting review', error); // eslint-disable-line
  }
};

export const sendReviewEmail = values => {
  emailjs.send('default_service', 'template_doi3hrj', values).then(
    function(response) {
      console.log('Email sent', response.status, response.text); // eslint-disable-line
      return true;
    },
    function(error) {
      console.log('Email failed', error); // eslint-disable-line
    }
  );
};
