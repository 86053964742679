/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import Button from './button';
import './styled-components/helper.scss';
import { postReview, sendReviewEmail } from '../../util/graphqlClient';

import {
  EmailFormBox,
  Input,
  TwoInputContainer,
  OneInputContainer,
  SmallInputStack,
  Label,
  TextArea,
  Checkbox,
  CheckboxLabel,
} from './styled-components/formStyledComponents';

const handleSubmit = async (values, setSubmitting) => {
  setSubmitting(true);
  const result = await postReview(values);
  if (result) {
    sendReviewEmail(values);
    setSubmitting(false);
    navigate('/thanks', { state: { mode: 'reviews' } });
  }
};

const ReviewForm = () => {
  return (
    <EmailFormBox leftMargin={true} id="review-form">
      <Formik
        initialValues={{
          botField: '',
          body: '',
          email: '',
          location: '',
          nickName: '',
          wasCommission: false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          botField: Yup.string(),
          body: Yup.string().required('Please enter your review'),
          // .matches(/[A-Za-z\d]/g, {
          //   message: 'Use no special characters',
          //   excludeEmptyString: true,
          // }),
          email: Yup.string()
            .email()
            .required('Email Address Required'),
          location: Yup.string(),
          // .matches(/[A-Za-z\d]/g, {
          //   message: 'Use no special characters',
          //   excludeEmptyString: true,
          // }),
          nickName: Yup.string().required('Nickname Required'),
          // .matches(/[A-Za-z\d]/g, {
          //   message: 'Use no special characters',
          //   excludeEmptyString: true,
          // }),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          } = props;
          return (
            <form
              name={'reviewForm'}
              method="post"
              action="/"
              onSubmit={handleSubmit}
            >
              <OneInputContainer>
                {/*body*/}
                <Label htmlFor="body" style={{ display: 'block' }}>
                  Review *
                </Label>
                <TextArea
                  id="body"
                  name="body"
                  placeholder="Tell us about your experience."
                  type="textarea"
                  rows="8"
                  columns="50"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.body && touched.body
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.body && touched.body && (
                  <div className="input-feedback">{errors.body}</div>
                )}
              </OneInputContainer>

              <OneInputContainer>
                {/*email*/}
                <Label htmlFor="email" style={{ display: 'block' }}>
                  Email *
                </Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Enter your email. It will not be displayed."
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </OneInputContainer>

              <OneInputContainer>
                {/*nickName*/}
                <Label htmlFor="nickName" style={{ display: 'block' }}>
                  Nickname *
                </Label>
                <TextArea
                  id="nickName"
                  name="nickName"
                  placeholder="Enter a nickname, e.g. Jen H."
                  type="text"
                  value={values.nickName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nickName && touched.nickName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.nickName && touched.nickName && (
                  <div className="input-feedback">{errors.nickName}</div>
                )}
              </OneInputContainer>

              <OneInputContainer>
                {/*location*/}
                <Label htmlFor="location" style={{ display: 'block' }}>
                  Location
                </Label>
                <TextArea
                  id="location"
                  name="location"
                  placeholder="Enter a location, eg Wesport, CT"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.location && touched.location
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.location && touched.location && (
                  <div className="input-feedback">{errors.location}</div>
                )}
              </OneInputContainer>

              <TwoInputContainer>
                <SmallInputStack>
                  {/*bot field*/}
                  <Label htmlFor="botField">What is your favorite color?</Label>
                  <Input
                    id="botField"
                    name="botField"
                    placeholder="Favorite Color?"
                    type="text"
                    value={values.botField}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.botField && touched.botField
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                </SmallInputStack>
              </TwoInputContainer>

              <OneInputContainer className="container">
                {/*checkbox for join mailing list*/}
                <CheckboxLabel
                  htmlFor="wasCommission"
                  style={{ display: 'block' }}
                >
                  Was this a commission?
                  <Checkbox
                    id="wasCommission"
                    name="wasCommission"
                    type="checkbox"
                    value={values.wasCommission}
                    checked={values.wasCommission}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.wasCommission && touched.wasCommission
                        ? 'checkbox error'
                        : 'checkbox'
                    }
                  />
                  <span className="checkmark" />
                </CheckboxLabel>
                {errors.wasCommission && touched.wasCommission && (
                  <div className="input-feedback">{errors.wasCommission}</div>
                )}
              </OneInputContainer>

              <TwoInputContainer>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  text="submit"
                  small
                />
                <Button
                  type="button"
                  clickHandler={e => {
                    e.preventDefault();
                    resetForm();
                  }}
                  disabled={!dirty || isSubmitting}
                  text="Reset"
                  small
                  gray
                />
              </TwoInputContainer>
            </form>
          );
        }}
      </Formik>
    </EmailFormBox>
  );
};

ReviewForm.propTypes = {
  work: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
};

export default ReviewForm;
